import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { auth, db } from "../firebase";
import { GAME_MODES } from "../pages/Games/constants";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const signup = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return auth.signOut();
  };

  const resetPassword = (email) => {
    return auth.sendPasswordResetEmail(email);
  };

  const getGames = () => {
    const gamesRef = db.collection("games");
    return gamesRef
      .where("playerId", "==", currentUser.uid)
      .orderBy("date", "desc")
      .get();
  };

  const getScorecard = (scorecardId) => {
    const scorecardRef = db.collection("scorecards");
    return scorecardRef.where("id", "==", scorecardId).get();
  };

  const createGame = (game) => {
    try {
      const id = uuidv4();
      const cardId = uuidv4();

      db.collection("games")
        .doc(id)
        .set({
          ...game,
          playerId: currentUser.uid,
          id,
          strokes: game.strokes ?? 0,
          scorecardId:
            game.gameMode === GAME_MODES.SCORECARD
              ? game.scorecardId ?? cardId
              : 0,
        });

      if (!game.scorecardId && game.gameMode === GAME_MODES.SCORECARD) {
        db.collection("scorecards")
          .doc(cardId)
          .set({
            id: cardId,
            score: Array.from(Array(4)).map(() => ({
              player: "",
              0: "",
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
              17: "",
              total: 0,
            })),
          });
      }
    } catch (e) {
      console.error("Failed to add game", e);
    }
  };

  const deleteGame = (id) => {
    try {
      db.collection("games").doc(id).delete();
      db.collection("scorecards")
        .where("gameId", "array-contains", id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data()?.gameId?.length === 1) {
              doc.ref.delete();
            } else {
              doc.ref.update({
                gameId: doc.data()?.gameId?.filter((game) => game !== id),
              });
            }
          });
        });
    } catch (e) {
      console.error("Failed to delete game", e);
    }
  };

  const updateScorecard = (scorecard) => {
    try {
      db.collection("scorecards")
        .doc(scorecard.id)
        .set({
          ...scorecard,
        });
    } catch (e) {
      console.error("Failed to update scorecard", e);
    }
  };

  const updateGameScore = (gameId, newStrokes, coursePar) => {
    try {
      db.collection("games")
        .doc(gameId)
        .update({
          strokes: newStrokes,
          score: newStrokes - coursePar,
        });
    } catch (e) {
      console.error("Failed to update game score", e);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    getGames,
    getScorecard,
    createGame,
    deleteGame,
    updateScorecard,
    updateGameScore,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
