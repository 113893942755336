import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useAuth();

  if (currentUser && currentUser.emailVerified) {
    return <Component {...rest} />;
  } else if (currentUser && !currentUser.emailVerified) {
    return <Navigate to="/verify" />;
  } else {
    return <Navigate to="/home" />;
  }
};
