import React from "react";
import { Box, Heading, Button, Text, Layer } from "grommet";

export const DeleteConfirmDialog = ({ onConfirm, onCancel }) => {
  return (
    <Layer
      background="white"
      animation="fadeIn"
      onEsc={onCancel}
      onClickOutside={onCancel}
      responsive={false}
    >
      <Box
        direction="column"
        pad={{ horizontal: "large", vertical: "small" }}
        width="medium"
        align="start"
        justify="center"
      >
        <Heading level={3}>Delete Game</Heading>

        <Box pad={{ vertical: "medium" }} gap="medium" direction="column">
          <Text>Are you sure you want to delete this game?</Text>
          <Box direction="row" justify="between">
            <Box width="small">
              <Button label="Cancel" onClick={onCancel} />
            </Box>
            <Box width="small" margin={{ horizontal: "small" }}>
              <Button
                label="Confirm"
                onClick={onConfirm}
                color="status-error"
                primary
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
