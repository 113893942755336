import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBoZWrJvvrPakLszRy-HETCvf83GLtJOME",
  authDomain: "stroke-it.firebaseapp.com",
  projectId: "stroke-it",
  storageBucket: "stroke-it.appspot.com",
  messagingSenderId: "579308425003",
  appId: "1:579308425003:web:1ca9a1d557d2d754c0ff6e",
  measurementId: "G-HTVTKFZL83",
});

export const auth = app.auth();
export const db = app.firestore();
export default app;
