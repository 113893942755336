/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Heading,
  Layer,
  DataTable,
  Text,
  TextInput,
  Button,
  ResponsiveContext,
  Pagination,
  Notification,
  Tip,
} from "grommet";
import { useAuth } from "../../context/AuthContext";
import { HelpOption } from "grommet-icons";
import { IsThisYouDialog } from "./IsThisYouDialog";

export const ScorecardDialog = ({ game, onConfirm, onCancel }) => {
  const { getScorecard, updateScorecard, updateGameScore } = useAuth();
  const [isQuestionDialogOpen, setIsQuestionDialogOpen] = useState(false);
  const [dbScorecard, setDbScorecard] = useState();
  const [scorecard, setScorecard] = useState();
  const [tableTemplate, setTableTemplate] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [error, setError] = useState();
  const [notification, setNotification] = useState({
    show: false,
    message: "",
  });

  const viewport = useContext(ResponsiveContext);
  const isDesktop = ["medium", "large"].includes(viewport);

  const handleConfirmClick = () => {
    const isValid = !error;

    if (isValid) {
      const playerScore = scorecard.find(
        (card) => card.playerId === game.playerId
      );
      updateScorecard({ ...dbScorecard, score: scorecard });

      if (playerScore) {
        updateGameScore(game.id, playerScore.total, game.coursePar);
      }
      onConfirm();
    }
  };

  const checkIfPlayerExists = (playerIndex) => {
    const playerIndexPlayer = scorecard[playerIndex].playerId;
    const playerExists = [
      scorecard[0].playerId,
      scorecard[1].playerId,
      scorecard[2].playerId,
      scorecard[3].playerId,
    ].includes(game.playerId);

    if (!playerIndexPlayer && !playerExists) {
      setIsQuestionDialogOpen({ index: playerIndex, isOpen: true });
    }
  };

  const handleScorecardUpdate = (playerIndex, updateVal, key) => {
    const newScore = [...scorecard];
    let total = 0;

    newScore[playerIndex][key] = updateVal;
    Object.keys(newScore[playerIndex]).forEach((scorekey) => {
      if (!["player", "playerId", "total"].includes(scorekey)) {
        total += parseInt(
          newScore[playerIndex][scorekey]?.length
            ? newScore[playerIndex][scorekey]
            : 0,
          10
        );
      }
    });
    newScore[playerIndex].total = total;
    setScorecard(newScore);
  };

  const handlePageChange = (e) => {
    setPageNum(e.page);
  };

  const handleCopyCardId = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(game?.scorecardId)
        .then(() => {
          setNotification({
            show: true,
            isError: false,
            message: "Card ID successfully copied to clipboard",
          });
        })
        .catch((e) => {
          setNotification({
            show: true,
            isError: true,
            message: "Failed to copy card ID to clipboard",
          });
        });
    } else {
      setNotification({
        show: true,
        isError: true,
        message: "Failed to copy card ID to clipboard",
      });
    }
  };

  const getGameScorecard = async () => {
    const data = await getScorecard(game.scorecardId);
    data.docs.forEach((e) => {
      setDbScorecard(e.data());
      setScorecard(e.data().score);
    });
  };

  const validateScore = (score) => {
    if (score) {
      const number = parseInt(score, 10);
      const isValid =
        number &&
        !Number.isNaN(number) &&
        !score.includes(".") &&
        !score.includes("-");

      if (!isValid) {
        setError("Invalid number of strokes");
      } else {
        setError(false);
      }
    }
  };

  useEffect(() => {
    const template = [];
    scorecard?.forEach((s, i) => {
      template.push({
        player: (
          <TextInput
            className="scorecard-player"
            value={scorecard[i].player ?? s.player}
            placeholder={`Player ${i + 1}`}
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "player")}
            onBlur={() => {
              checkIfPlayerExists(i);
            }}
          />
        ),
        0: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "0")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[0]}
            placeholder="0"
          />
        ),
        1: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "1")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[1]}
            placeholder="0"
          />
        ),
        2: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "2")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[2]}
            placeholder="0"
          />
        ),
        3: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "3")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[3]}
            placeholder="0"
          />
        ),
        4: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "4")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[4]}
            placeholder="0"
          />
        ),
        5: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "5")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[5]}
            placeholder="0"
          />
        ),
        6: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "6")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[6]}
            placeholder="0"
          />
        ),
        7: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "7")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[7]}
            placeholder="0"
          />
        ),
        8: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "8")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[8]}
            placeholder="0"
          />
        ),
        9: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "9")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[9]}
            placeholder="0"
          />
        ),
        10: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "10")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[10]}
            placeholder="0"
          />
        ),
        11: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "11")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[11]}
            placeholder="0"
          />
        ),
        12: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "12")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[12]}
            placeholder="0"
          />
        ),
        13: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "13")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[13]}
            placeholder="0"
          />
        ),
        14: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "14")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[14]}
            placeholder="0"
          />
        ),
        15: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "15")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[15]}
            placeholder="0"
          />
        ),
        16: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "16")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[16]}
            placeholder="0"
          />
        ),
        17: (
          <TextInput
            onChange={(e) => handleScorecardUpdate(i, e.target.value, "17")}
            onBlur={(e) => validateScore(e.target.value)}
            value={s[17]}
            placeholder="0"
          />
        ),
        total: (
          <Box pad="16px">
            <Text weight="bold">{s.total}</Text>
          </Box>
        ),
      });
    });
    setTableTemplate(template);
  }, [scorecard]);

  useEffect(() => {
    getGameScorecard();
  }, []);

  useEffect(() => {
    const maxCols = isDesktop ? 9 : 3;
    const columns = [
      {
        property: "player",
        header: <Text>Holes</Text>,
        primary: true,
        size: isDesktop ? "80px" : "auto",
      },
    ];

    for (let i = 1; i <= maxCols; i++) {
      const property = i + maxCols * (pageNum - 1) - 1;

      columns.push({
        property: property.toString(),
        header: <Text>{property + 1}</Text>,
        primary: true,
        size: isDesktop ? "30px" : "auto",
      });
    }

    columns.push({
      property: "total",
      header: <Text>Total</Text>,
      primary: true,
      size: isDesktop ? "30px" : "auto",
    });

    setColumns(columns);
  }, [pageNum, isDesktop]);

  if (!tableTemplate?.length) {
    return (
      <Layer
        background="white"
        animation="fadeIn"
        onEsc={onCancel}
        onClickOutside={onCancel}
      >
        <Box width="xlarge" pad="large" align="center" justify="center">
          <Heading level="3">Loading...</Heading>
        </Box>
      </Layer>
    );
  }

  return (
    <Layer
      background="white"
      animation="fadeIn"
      onEsc={onCancel}
      onClickOutside={onCancel}
    >
      {isQuestionDialogOpen.isOpen && (
        <IsThisYouDialog
          playerIndex={isQuestionDialogOpen.index}
          onNo={() => setIsQuestionDialogOpen({ isOpen: false })}
          onYes={(playerIndex) => {
            handleScorecardUpdate(playerIndex, game.playerId, "playerId");
            setIsQuestionDialogOpen({ isOpen: false });
          }}
        />
      )}

      {notification.show && (
        <Notification
          toast
          status={notification.isError ? "critical" : "normal"}
          title={notification.isError ? "Error" : "Copy to Clipboard"}
          message={notification.message}
          onClose={() => setNotification({ show: false, message: "" })}
        />
      )}

      <Box
        direction="column"
        pad={{ horizontal: "large", vertical: isDesktop ? "small" : "large" }}
        width="xlarge"
        justify={isDesktop ? "start" : "center"}
        align="center"
        overflow="auto"
      >
        <Box flex="grow" fill="horizontal" direction="column">
          <Box
            justify="center"
            align="start"
            margin={{ vertical: "small", horizontal: "small" }}
          >
            <Heading color="dark-2" margin={{ vertical: "none" }} level="3">
              {game.course}
            </Heading>
            <Text>{game.date}</Text>
          </Box>
          <Box
            margin={{ bottom: "small" }}
            align="center"
            direction="row"
            gap="small"
            width="200px"
          >
            <Button
              onClick={handleCopyCardId}
              color="light-4"
              primary
              label="Copy Card ID"
            />
            <Tip
              className="scorecard-tip"
              content={
                <Box pad="medium" width="medium">
                  Share this card ID with other players who need to use this
                  scorecard. When the other player creates a game, they can add
                  this ID to the 'Scorecard ID' box in the 'Add Game' dialog in
                  order to share this card
                </Box>
              }
            >
              <HelpOption />
            </Tip>
          </Box>
        </Box>

        <Box flex="grow" align="center" fill="horizontal">
          <DataTable
            className="scorecard"
            pad="medium"
            margin="none"
            columns={columns}
            data={tableTemplate}
            background="white"
            border="bottom"
          />
          <Box margin={{ vertical: isDesktop ? "medium" : "large" }}>
            <Pagination
              onChange={handlePageChange}
              numberItems={18}
              step={columns.length - 2}
            />
          </Box>
        </Box>

        {error && (
          <Box direction="row" align="center" justify="center">
            <Text weight="bold" color="status-error">
              {error}
            </Text>
          </Box>
        )}

        <Box
          flex="grow"
          margin={{ vertical: "medium" }}
          gap="small"
          direction="row"
          justify="end"
          fill="horizontal"
        >
          <Box width="small">
            <Button label="Cancel" onClick={onCancel} />
          </Box>
          <Box width="small" margin={{ horizontal: "small" }}>
            <Button
              disabled={!!error}
              label="Save"
              onClick={handleConfirmClick}
              color="neutral-1"
              primary
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
