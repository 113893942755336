/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  DataTable,
  Text,
  Card,
  Box,
  Button,
  Heading,
  Tip,
  ResponsiveContext,
} from "grommet";
import { Trash, Document } from "grommet-icons";
import { useAuth } from "../../context/AuthContext";
import { EmptyState } from "./EmptyState";
import { AddGameDialog } from "./AddGameDialog";
import { DeleteConfirmDialog } from "./DeleteConfirmDialog";
import { ScorecardDialog } from "./ScorecardDialog";
import { GAME_MODES } from "./constants";

export const Games = () => {
  const { getGames, deleteGame } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setDeleteIsOpen] = useState(false);
  const [isScorecardOpen, setIsScorecardOpen] = useState(false);
  const [games, setGames] = useState();
  const [deleteId, setDeleteId] = useState();
  const [selectedGame, setSelectedGame] = useState();

  const viewport = useContext(ResponsiveContext);
  const isDesktop = ["medium", "large"].includes(viewport);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleOnAddGame = () => {
    getUserGames();
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setDeleteIsOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteIsOpen(false);
  };

  const handleDeleteConfirm = () => {
    deleteGame(deleteId);
    setGames(games.filter((game) => game.id !== deleteId));
    setDeleteIsOpen(false);
  };

  const handleOpenScorecard = (game) => {
    setSelectedGame(game);
    setIsScorecardOpen(true);
  };

  const handleScorecardCancel = () => {
    setIsScorecardOpen(false);
  };

  const handleScorecardConfirm = () => {
    handleScorecardCancel();
    getUserGames();
  };

  const getUserGames = async () => {
    const data = await getGames();
    const result = data.docs.map((e) => ({
      ...e.data(),
      score:
        parseInt(e.data().score, 10) > 0
          ? `+${e.data().score}`
          : e.data().score,
      scorecard: (
        <Tip content="View Scorecard">
          <Button
            className="icon-button"
            disabled={e.data().gameMode === GAME_MODES.TOTAL_SCORE}
            onClick={() => handleOpenScorecard(e.data())}
            label={<Document />}
          />
        </Tip>
      ),
      delete: (
        <Tip content="Delete Game">
          <Button
            className="icon-button"
            label={
              <Trash
                color="#bb0000"
                onClick={() => handleOpenDeleteDialog(e.data().id)}
              />
            }
          />
        </Tip>
      ),
    }));
    setGames(result);
  };

  useEffect(() => {
    getUserGames();
  }, []);

  if (!games) {
    return (
      <Box margin="large" justify="center" align="center">
        <Card width="medium">
          <Box align="center" justify="center" pad="large" background="white">
            <Heading level="3" textAlign="center">
              Loading...
            </Heading>
          </Box>
        </Card>
      </Box>
    );
  }

  if (!games?.length) {
    return (
      <Box margin="large" justify="center" align="center">
        <Card width="large">
          <Box align="center" justify="center" pad="large" background="white">
            <EmptyState onAddGame={handleOnAddGame} />
          </Box>
        </Card>
      </Box>
    );
  }

  return (
    <Box align="center" justify="center" pad="large">
      {isOpen && (
        <AddGameDialog setIsOpen={setIsOpen} onAdd={handleOnAddGame} />
      )}

      {isDeleteOpen && (
        <DeleteConfirmDialog
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />
      )}

      {isScorecardOpen && (
        <ScorecardDialog
          game={selectedGame}
          onConfirm={handleScorecardConfirm}
          onCancel={handleScorecardCancel}
        />
      )}

      <Box direction="column" width="large" align={isDesktop ? "" : "center"}>
        <Heading>Games Played</Heading>
        <Box direction="row" justify={isDesktop ? "end" : "center"}>
          <Box
            width={isDesktop ? "small" : "large"}
            margin={{ vertical: "small" }}
          >
            <Button
              primary
              color="neutral-3"
              label="New Game"
              onClick={handleOpenDialog}
            />
          </Box>
        </Box>

        {isDesktop ? (
          <Card className="card">
            <DataTable
              className="games-table"
              pad="medium"
              columns={[
                {
                  property: "date",
                  header: <Text>Date</Text>,
                  primary: true,
                },
                {
                  property: "course",
                  header: <Text>Course</Text>,
                  primary: true,
                },
                {
                  property: "coursePar",
                  header: <Text>Course Par</Text>,
                  primary: true,
                },
                {
                  property: "strokes",
                  header: <Text>Strokes</Text>,
                  primary: true,
                },
                {
                  property: "score",
                  header: <Text>Score</Text>,
                  primary: true,
                },
                {
                  property: "scorecard",
                  header: <Text></Text>,
                  primary: true,
                  size: "30px",
                },
                {
                  property: "delete",
                  header: <Text></Text>,
                  primary: true,
                  size: "30px",
                },
              ]}
              data={games}
              background="white"
            />
          </Card>
        ) : (
          <Box direction="column" align="center">
            {games.map((game, i) => (
              <Card
                className="card"
                key={i}
                width="medium"
                margin="medium"
                pad="medium"
                background="light-2"
              >
                <Box
                  margin={{ vertical: "small" }}
                  direction="row"
                  justify="end"
                  gap="medium"
                >
                  <Trash
                    size="16px"
                    color="#bb0000"
                    onClick={() => handleOpenDeleteDialog(game.id)}
                  />
                </Box>
                <Box
                  margin={{ vertical: "small" }}
                  direction="row"
                  justify="between"
                >
                  <Text weight="bold">Date</Text>
                  <Text>{game.date}</Text>
                </Box>
                <Box
                  margin={{ vertical: "small" }}
                  direction="row"
                  justify="between"
                >
                  <Text weight="bold">Course</Text>
                  <Text>{game.course}</Text>
                </Box>
                <Box
                  margin={{ vertical: "small" }}
                  direction="row"
                  justify="between"
                >
                  <Text weight="bold">Score</Text>
                  <Text>{game.score}</Text>
                </Box>
                <Box
                  margin={{ vertical: "small" }}
                  direction="row"
                  justify="between"
                >
                  <Text weight="bold">Strokes</Text>
                  <Text>{game.strokes}</Text>
                </Box>
                <Box
                  margin={{ vertical: "small" }}
                  direction="row"
                  justify="end"
                  gap="medium"
                >
                  {game.gameMode === GAME_MODES.SCORECARD && (
                    <Button
                      onClick={() => handleOpenScorecard(game)}
                      size="small"
                      color="white"
                      primary
                      label={
                        <Box
                          direction="row"
                          align="center"
                          justify="center"
                          gap="small"
                        >
                          <Text>View Scorecard</Text>
                        </Box>
                      }
                    />
                  )}
                </Box>
              </Card>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
