import React from "react";
import { Box, Heading } from "grommet";

export const Error404 = () => {
  return (
    <Box align="center" justify="center" pad="large">
      <Box
        align="center"
        direction="column"
        pad={{ vertical: "large" }}
        width="large"
      >
        <Heading color="status-error" textAlign="center" level="3">
          Triple Bogey!
        </Heading>
        <Heading color="status-error" level="2" textAlign="center">
          404 Page Not Found
        </Heading>
      </Box>
    </Box>
  );
};
