import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeContext } from "grommet";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import { LandingPage } from "./pages/LandingPage/LandingPage";
import { LoginPage } from "./pages/Login/Login";
import { RegisterPage } from "./pages/RegisterPage/RegisterPage";
import { Games } from "./pages/Games/Games";
import { Header } from "./components/Header/Header";
import "./App.css";
import { Error404 } from "./pages/ErrorPages/Error404";
import { VerifyPage } from "./pages/VerifyPage/VerifyPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import { Analysis } from "./pages/Analysis/Analysis";
import { PlayerHome } from "./pages/PlayerHome/PlayerHome";

function App() {
  return (
    <ThemeContext.Extend
      value={{
        tip: {
          content: {
            background: "white",
            border: {
              color: "dark-4",
              size: "small",
            },
          },
        },
      }}
    >
      <div className="app">
        <Router>
          <AuthProvider>
            <Header />
            <Routes>
              <Route path="/home" element={<LandingPage />} />
              <Route path="/analysis" element={<Analysis />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/verify" element={<VerifyPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route
                exact
                path="/games"
                element={<PrivateRoute component={Games} />}
              />
              <Route
                exact
                path="/"
                element={<PrivateRoute component={PlayerHome} />}
              />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </AuthProvider>
        </Router>
      </div>
    </ThemeContext.Extend>
  );
}

export default App;
