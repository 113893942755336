import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import {
  Box,
  Card,
  CardBody,
  TextInput,
  Button,
  Text,
  Anchor,
  Heading,
  ResponsiveContext,
} from "grommet";
import { useAuth } from "../../context/AuthContext";

export const LoginPage = () => {
  const { login, currentUser } = useAuth();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const viewport = useContext(ResponsiveContext);
  const isMobile = viewport === "small";

  const handleLogin = async () => {
    try {
      setError("");
      await login(userName, password);
    } catch (e) {
      setError("Invalid Email or Password");
    }
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return !currentUser ? (
    <Box
      pad={isMobile ? "small" : "large"}
      justify="center"
      align="center"
      gap="small"
    >
      <Box margin={{ vertical: "medium" }}>
        <Heading margin="none" weight="bold">
          Login
        </Heading>
      </Box>
      <Card className="card" height="medium" width="large" background="light-1">
        <CardBody pad="medium">
          <Box gap="medium" pad="medium" fill="vertical" justify="center">
            <Box direction="column">
              <Text weight="bold" margin={{ bottom: "xsmall" }}>
                Email
              </Text>
              <TextInput
                onChange={(e) => setUserName(e.target.value)}
                onKeyDown={handleSubmit}
                placeholder="youremail@example.com"
              />
            </Box>
            <Box direction="column">
              <Text weight="bold" margin={{ bottom: "xsmall" }}>
                Password
              </Text>
              <TextInput
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleSubmit}
                type="password"
                placeholder="***********"
              />
            </Box>
            <Box direction="column" align="center" gap="small">
              <Button
                className="login-button"
                onClick={handleLogin}
                size="medium"
                color="neutral-3"
                label="Login"
                primary
              />
              <Box fill="horizontal" direction="row" justify="between">
                <Anchor
                  size="small"
                  href="/forgot-password"
                  label="forgot your password?"
                />
                <Text size="small" textAlign="end">
                  don't have an account? &nbsp;
                  <Anchor size="small" href="/register" label="register here" />
                </Text>
              </Box>
            </Box>
          </Box>
          {error && (
            <Text
              weight="bold"
              size="small"
              textAlign="center"
              color="status-error"
            >
              {error}
            </Text>
          )}
        </CardBody>
      </Card>
    </Box>
  ) : (
    <Navigate replace to="/" />
  );
};
