import React, { useContext } from "react";
import { Heading, Button, Box, Text, ResponsiveContext } from "grommet";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/Logo/Logo";
import GolfClubIcon from "../../images/golf-club.svg";

export const LandingPage = () => {
  const navigate = useNavigate();
  const viewport = useContext(ResponsiveContext);
  const isDesktop = ["medium", "large"].includes(viewport);

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <Box
      className="hero"
      // background="light-2"
      direction="column"
      justify="center"
      align="center"
      pad="large"
    >
      <div className="overlay" />
      <Box direction={isDesktop ? "row" : "column"}>
        <Box
          direction="column"
          align="center"
          justify="center"
          className="hero-wrapper"
          margin={{
            right: isDesktop ? "large" : "none",
          }}
          pad={{
            top: isDesktop ? "none" : "xlarge",
          }}
        >
          <img height={100} src={GolfClubIcon} alt="Golf Club Icon" />
          <Logo />
          <Box margin={{ bottom: "medium", top: "small" }}>
            <Heading
              className="hero-content"
              color="dark-3"
              margin="small"
              level={4}
              textAlign="center"
              fill="horizontal"
            >
              Keep track of how bad you are at golf, one stroke at a time
            </Heading>
          </Box>

          <Button
            className="hero-content login-button"
            size="large"
            primary
            color="neutral-3"
            label="Login"
            onClick={handleLoginClick}
          />
        </Box>
        <Box
          className="hero-content"
          margin={{
            left: isDesktop ? "large" : "none",
            top: isDesktop ? "none" : "100px",
          }}
          border={{ color: "light-4", size: "small" }}
          align="start"
          justify="center"
          gap="large"
          width="large"
          pad="large"
          background="white"
        >
          <Heading margin="none" level="3" weight="bold">
            What we provide
          </Heading>
          <Text color="dark-3">
            Stroke-It lets you keep track of your golf progress by entering your
            golf scores and tracking your progress over time. Just played a game
            of golf? Add your game to your account and see how you improve over
            time! Use our virtual scorecards to keep detailed game scores, or
            enter game totals for more general scorekeeping.
          </Text>
          <Text color="dark-3">
            Ditch the old pencil and paper and enter live scores using our
            virtual scorecards. Let one player create a scorecard and share it
            with other players using our <strong>SHARE</strong> feature so you
            can keep track and update your group's scores in-game.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
