/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useAuth } from "../../context/AuthContext";
import { Box, Button, Card, Heading, ResponsiveContext } from "grommet";
import { AddGameDialog } from "../Games/AddGameDialog";
import { EmptyState } from "../Games/EmptyState";
import { OverviewTile } from "./OverviewTile";

export const PlayerHome = () => {
  const { getGames } = useAuth();
  const [isAddGameDialogOpen, setIsAddGameDialogOpen] = useState(false);
  const [games, setGames] = useState();
  const viewport = useContext(ResponsiveContext);
  const isDesktop = ["medium", "large"].includes(viewport);

  const getUserGames = async () => {
    const data = await getGames();
    const result = data.docs.map((e) => ({
      ...e.data(),
    }));
    setGames(result);
  };

  const handleOpenAddGameDialog = () => {
    setIsAddGameDialogOpen(true);
  };

  const handleOnAddGame = () => {
    getUserGames();
  };

  useEffect(() => {
    getUserGames();
  }, []);

  const totalGames = games?.length ?? 1;
  let totalStrokes = 0;
  let totalScore = 0;
  let averageScore = 0;
  let averageStrokes = 0;
  let diffDays = 0;

  if (games) {
    games.forEach((game) => {
      totalStrokes += parseInt(game.strokes, 10);
      totalScore += parseInt(game.score, 10);
    });

    averageScore = Math.ceil(totalScore / totalGames);
    averageStrokes = Math.ceil(totalStrokes / totalGames);

    const date1 = new Date();
    const date2 = games.reduce(function (a, b) {
      return a > new Date(b.date) ? a : new Date(`${b.date} 8:00`);
    }, 0);
    console.log(date2);

    const diffTime = Math.abs(date2 - date1);
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  if (!games) {
    return (
      <Box margin="large" justify="center" align="center">
        <Card width="medium">
          <Box align="center" justify="center" pad="large" background="white">
            <Heading level="3" textAlign="center">
              Loading...
            </Heading>
          </Box>
        </Card>
      </Box>
    );
  }

  if (!games?.length) {
    return (
      <Box margin="large" justify="center" align="center">
        <Card width="large">
          <Box align="center" justify="center" pad="large" background="white">
            <EmptyState onAddGame={handleOnAddGame} />
          </Box>
        </Card>
      </Box>
    );
  }

  return (
    <Box align="center" justify="center" pad="large">
      {isAddGameDialogOpen && (
        <AddGameDialog
          setIsOpen={setIsAddGameDialogOpen}
          onAdd={handleOnAddGame}
        />
      )}

      <Box direction="column" width="large" align="center">
        <Heading>Player Overview</Heading>
        <Box
          width={isDesktop ? "medium" : "large"}
          margin={{ vertical: "small" }}
        >
          <Button
            primary
            margin={{ bottom: "small" }}
            color="neutral-3"
            label="Add New Game"
            onClick={handleOpenAddGameDialog}
          />
        </Box>
        <Box margin={{ bottom: "medium" }} direction="row" align="center">
          <Heading color="dark-1" textAlign="center" level={3}>
            It has been <span className="overview-diff-days">{diffDays}</span>{" "}
            days since your last game
          </Heading>
        </Box>
        <Box
          direction={isDesktop ? "row" : "column"}
          width={isDesktop ? null : "medium"}
          gap="medium"
        >
          <OverviewTile title="Games Played" content={totalGames} />
          <OverviewTile
            title="Average Score"
            content={averageScore > 0 ? `+${averageScore}` : averageScore}
          />
          <OverviewTile title="Average Strokes" content={averageStrokes} />
        </Box>
      </Box>
    </Box>
  );
};
