import React from "react";
import { useAuth } from "../../context/AuthContext";
import { Box, Anchor, Heading, Text, Card } from "grommet";
import { Navigate } from "react-router-dom";

export const VerifyPage = () => {
  const { currentUser } = useAuth();

  if (currentUser && currentUser.emailVerified) {
    return <Navigate to="/" />;
  }

  if (currentUser && !currentUser.emailVerified) {
    return (
      <Box align="center" justify="center" height="medium">
        <Card
          background="white"
          pad={{ bottom: "large", top: "small", horizontal: "large" }}
        >
          <Heading level="2">Please verify your email address.</Heading>
          <Text>
            Didn't get an email? Check your spam folder or&nbsp;
            <Anchor label="click here" />
            &nbsp;to resend.
          </Text>
          <Text margin={{ vertical: "medium" }}>
            If you've just verified your email, please reload the page to
            continue.
          </Text>
        </Card>
      </Box>
    );
  }

  return <Navigate to="/home" />;
};
