import { Box, Heading, Layer, Text, Button } from "grommet";

export const IsThisYouDialog = ({ playerIndex, onNo, onYes }) => {
  return (
    <Layer
      background="white"
      animation="fadeIn"
      onEsc={onNo}
      onClickOutside={onNo}
      responsive={false}
    >
      <Box
        direction="column"
        pad={{ horizontal: "large", vertical: "small" }}
        width="medium"
        align="start"
        justify="center"
      >
        <Heading level={3}>Is this player yourself?</Heading>

        <Box pad={{ vertical: "medium" }} gap="medium" direction="column">
          <Text>
            If you assign this player as yourself, the score for this player
            will be synced with your game.
          </Text>
          <Text>This cannot be unset once it is done.</Text>
          <Box direction="row" justify="between">
            <Box width="small">
              <Button label="No" onClick={onNo} color="status-error" primary />
            </Box>
            <Box width="small" margin={{ horizontal: "small" }}>
              <Button
                label="Yes"
                onClick={() => {
                  onYes(playerIndex);
                }}
                color="status-ok"
                primary
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
