import { useContext, useState } from "react";
import { Box, Text, ResponsiveContext, Layer } from "grommet";
import { Close, Menu } from "grommet-icons";
import { Link } from "react-router-dom";
import { Logo } from "../Logo/Logo";
import { useAuth } from "../../context/AuthContext";
import GolfClubIcon from "../../images/golf-club-white.svg";

export const Header = () => {
  const { currentUser, logout } = useAuth();
  const viewport = useContext(ResponsiveContext);
  const isDesktop = ["medium", "large"].includes(viewport);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <Box
      className="header"
      background="#008160"
      elevation="small"
      pad={{
        horizontal: "medium",
        vertical: "medium",
      }}
      align="center"
      direction="row"
    >
      {!isDesktop && (
        <Box margin={{ left: "small", right: "large", top: "10px" }}>
          {isMobileMenuOpen && (
            <Layer
              onEsc={closeMobileMenu}
              onClickOutside={closeMobileMenu}
              animation="fadeIn"
              position="left"
              responsive
              background="light-2"
            >
              <Box pad={{ vertical: "xlarge" }} gap="large" fill="vertical">
                {!currentUser && (
                  <Box gap="medium" direction="column" align="center">
                    <Link onClick={closeMobileMenu} to="/login">
                      <Text color="dark-2" weight="bold" size="medium">
                        Login
                      </Text>
                    </Link>
                    <Link onClick={closeMobileMenu} to="/register">
                      <Text color="dark-2" weight="bold" size="medium">
                        Register
                      </Text>
                    </Link>

                    <Close color="dark-2" onClick={closeMobileMenu} />
                  </Box>
                )}
                {currentUser && (
                  <Box
                    fill="vertical"
                    gap="large"
                    direction="column"
                    align="center"
                    pad={{ vertical: "xlarge" }}
                  >
                    <Link onClick={closeMobileMenu} to="/">
                      <Text color="dark-2" weight="bold" size="medium">
                        Overview
                      </Text>
                    </Link>
                    <Link onClick={closeMobileMenu} to="/games">
                      <Text color="dark-2" weight="bold" size="medium">
                        Games
                      </Text>
                    </Link>
                    <Link onClick={closeMobileMenu} to="/analysis">
                      <Text color="dark-2" weight="bold" size="medium">
                        Analysis
                      </Text>
                    </Link>
                    <Link
                      onClick={() => {
                        logout();
                        closeMobileMenu();
                      }}
                      to="/"
                    >
                      <Text color="dark-2" weight="bold" size="medium">
                        Logout
                      </Text>
                    </Link>

                    <Close color="dark-2" onClick={closeMobileMenu} />
                  </Box>
                )}
              </Box>
            </Layer>
          )}
          <Menu onClick={() => setIsMobileMenuOpen(true)} />
        </Box>
      )}

      <Box direction="row" align="center">
        <img className="logo" src={GolfClubIcon} alt="Golf Ball Icon" />
        <Link to="/">
          <Logo color="white" size="small" />
        </Link>
      </Box>

      {isDesktop && (
        <Box
          pad={{
            horizontal: "large",
            vertical: "small",
          }}
          gap="large"
          flex="grow"
          align="center"
          justify="end"
          direction="row"
        >
          {!currentUser && (
            <Box gap="medium" direction="row" align="center">
              <Link to="/login">
                <Text color="white" weight="bold" size="medium">
                  Login
                </Text>
              </Link>
              <Link to="/register">
                <Text color="white" weight="bold" size="medium">
                  Register
                </Text>
              </Link>
            </Box>
          )}
          {currentUser && (
            <Box gap="medium" direction="row" align="center">
              <Link to="/">
                <Text color="white" weight="bold" size="medium">
                  Overview
                </Text>
              </Link>
              <Link to="/games">
                <Text color="white" weight="bold" size="medium">
                  Games
                </Text>
              </Link>
              <Link to="/analysis">
                <Text color="white" weight="bold" size="medium">
                  Analysis
                </Text>
              </Link>
              <Link onClick={logout} to="/">
                <Text color="white" weight="bold" size="medium">
                  Logout
                </Text>
              </Link>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
