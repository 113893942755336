import React, { useState } from "react";
import { Box, Heading, Button } from "grommet";
import { Flag } from "grommet-icons";
import { AddGameDialog } from "./AddGameDialog";

export const EmptyState = ({ onAddGame }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <Box pad="xlarge" direction="column" justify="center" align="center">
      <Box align="center" justify="center">
        <Flag size="large" color="red" />
        <Heading textAlign="center" size="small">
          You have no games to display
        </Heading>
        <Button
          onClick={handleClick}
          size="large"
          primary
          color="neutral-1"
          label="Add a game"
        />

        {isOpen && (
          <AddGameDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onAdd={onAddGame}
          />
        )}
      </Box>
    </Box>
  );
};
