import React, { useState, useContext } from "react";
import {
  Box,
  Card,
  CardBody,
  TextInput,
  Button,
  Text,
  Heading,
  ResponsiveContext,
} from "grommet";
import { useAuth } from "../../context/AuthContext";

export const ForgotPasswordPage = () => {
  const { resetPassword } = useAuth();
  const [userName, setUserName] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState();
  const viewport = useContext(ResponsiveContext);
  const isMobile = viewport === "small";

  const handleReset = async () => {
    try {
      setError("");
      await resetPassword(userName);
      setEmailSent(true);
    } catch (e) {
      setError("Invalid Email or Password");
    }
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      handleReset();
    }
  };

  return (
    <Box
      pad={isMobile ? "small" : "large"}
      justify="center"
      align="center"
      gap="small"
    >
      <Box margin={{ vertical: "medium" }}>
        <Heading margin="none" weight="bold">
          Reset Password
        </Heading>
      </Box>
      <Card className="card" height="medium" width="large" background="light-1">
        <CardBody pad="medium">
          {!emailSent ? (
            <Box gap="medium" pad="medium" fill="vertical" justify="center">
              <Text weight="bold">
                Really? You couldn't remember your password?
              </Text>
              <Text margin={{ bottom: "xsmall" }}>
                Enter your email address and we'll send you a link to reset your
                password
              </Text>
              <Box direction="column">
                <TextInput
                  onChange={(e) => setUserName(e.target.value)}
                  onKeyDown={handleSubmit}
                  placeholder="youremail@example.com"
                />
              </Box>
              <Box direction="column" align="center" gap="small">
                <Button
                  className="login-button"
                  onClick={handleReset}
                  size="medium"
                  color="neutral-3"
                  label="Send Reset Link"
                  primary
                />
              </Box>
            </Box>
          ) : (
            <Box fill="vertical" justify="center" align="center" gap="medium">
              <Text weight="bold">
                An email has been sent to your email address. Try to remember it
                this time 😉
              </Text>
              <Text>
                Didn't get an email? Check your spam folder or reload the page
                to try again
              </Text>
            </Box>
          )}
          {error && (
            <Text
              weight="bold"
              size="small"
              textAlign="center"
              color="status-error"
            >
              {error}
            </Text>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};
