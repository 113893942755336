import React, { useState, useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import {
  Box,
  Card,
  CardBody,
  TextInput,
  Button,
  Text,
  Anchor,
  Heading,
  ResponsiveContext,
} from "grommet";
import { useAuth } from "../../context/AuthContext";

export const RegisterPage = () => {
  const { signup, currentUser } = useAuth();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const viewport = useContext(ResponsiveContext);
  const isMobile = viewport === "small";

  const handleClick = async () => {
    try {
      setError("");
      await signup(userName, password);
    } catch (e) {
      switch (e.code) {
        case "auth/email-already-in-use":
          setError("This email already in use by another account");
          break;
        case "auth/invalid-email":
          setError("Invalid email address format");
          break;
        case "auth/weak-password":
          setError(
            "This password is too weak, please enter a stronger password"
          );
          break;
        default:
          setError("An unexpected error occurred");
      }
    }
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  useEffect(() => {
    if (currentUser && !currentUser.emailVerified) {
      currentUser.sendEmailVerification();
    }
  }, [currentUser]);

  return !currentUser ? (
    <Box
      pad={isMobile ? "small" : "large"}
      justify="center"
      align="center"
      gap="small"
    >
      <Box margin={{ vertical: "medium" }}>
        <Heading margin="none" weight="bold">
          Register
        </Heading>
      </Box>
      <Card className="card" height="medium" width="large" background="light-1">
        <CardBody pad="medium">
          <Box gap="medium" pad="medium" fill="vertical" justify="center">
            <Box direction="column">
              <Text weight="bold" margin={{ bottom: "xsmall" }}>
                Email
              </Text>
              <TextInput
                onChange={(e) => setUserName(e.target.value)}
                onKeyDown={handleSubmit}
                placeholder="youremail@example.com"
              />
            </Box>
            <Box direction="column">
              <Text weight="bold" margin={{ bottom: "xsmall" }}>
                Password
              </Text>
              <TextInput
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleSubmit}
                type="password"
                placeholder="***********"
              />
            </Box>
            <Box direction="column" align="center" gap="small">
              <Button
                className="login-button"
                onClick={handleClick}
                size="medium"
                color="neutral-3"
                label="Register"
                primary
              />
              <Text size="small">
                already have an account? &nbsp;
                <Anchor size="small" href="/login" label="login" />
              </Text>
            </Box>
          </Box>
          {error && (
            <Text
              weight="bold"
              size="small"
              textAlign="center"
              color="status-error"
            >
              {error}
            </Text>
          )}
        </CardBody>
      </Card>
    </Box>
  ) : (
    <Navigate replace to="/" />
  );
};
