/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { Box, Card, DataChart, Heading } from "grommet";

export const Analysis = () => {
  const { getGames } = useAuth();
  const [games, setGames] = useState([]);

  const getUserGames = async () => {
    const data = await getGames();
    const result = data.docs.map((e) => ({
      ...e.data(),
    }));
    setGames(result);
  };

  useEffect(() => {
    getUserGames();
  }, []);

  return (
    <Box align="center" justify="center" pad="large">
      <Box direction="column" width="large" align="center">
        <Heading>Analysis</Heading>
        <Card
          fill="horizontal"
          className="card"
          background="white"
          margin={{ bottom: "medium" }}
        >
          <Box pad="small" align="center" justify="center">
            <Heading margin={{ bottom: "small", top: "none" }} level={3}>
              Strokes Over Time
            </Heading>
            <DataChart
              data={games
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((g) => ({
                  ...g,
                  date: `${g.date} 2:00 PM`,
                  strokes: parseInt(g.strokes, 10),
                }))}
              series={[
                { property: "date", label: "Date" },
                { property: "strokes", label: "Strokes" },
                { property: "course", label: "Course" },
              ]}
              chart={[
                {
                  property: "strokes",
                  type: "line",
                  opacity: "medium",
                  thickness: "xsmall",
                  color: "dark-3",
                },
                {
                  property: "strokes",
                  type: "point",
                  point: "circle",
                  thickness: "small",
                },
              ]}
              detail
              guide={{ x: { granularity: "fine" }, y: { granularity: "fine" } }}
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
