import { useContext } from "react";
import { Box, Card, Heading, ResponsiveContext } from "grommet";

export const OverviewTile = ({ title, content }) => {
  const viewport = useContext(ResponsiveContext);
  const isDesktop = ["medium", "large"].includes(viewport);

  return (
    <Card
      className="card"
      background="white"
      margin={{ bottom: "medium" }}
      pad={isDesktop ? "medium" : { vertical: "large" }}
      width="medium"
    >
      <Box fill="vertical" pad="small" align="center" justify="between">
        <Heading
          textAlign="center"
          margin={{ bottom: isDesktop ? "small" : "medium", top: "none" }}
          level={isDesktop ? 3 : 2}
        >
          {title}
        </Heading>
        <Heading color="#008160" margin="none" level={1}>
          {content}
        </Heading>
      </Box>
    </Card>
  );
};
