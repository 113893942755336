import React from "react";
import { Heading, Box } from "grommet";

export const Logo = ({ size, color }) => {
  const textSize = size === "small" ? "2em" : "4em";
  const logoColor = color ?? "dark-1";
  return (
    <Box className="hero-content" direction="row" align="center">
      <Heading
        className="logo-text"
        size={textSize}
        color={logoColor}
        margin="none"
      >
        stroke-it
      </Heading>
    </Box>
  );
};
