import React, { useState } from "react";
import {
  Box,
  Heading,
  TextInput,
  Button,
  Text,
  Layer,
  Tip,
  RadioButtonGroup,
} from "grommet";
import { HelpOption } from "grommet-icons";
import { useAuth } from "../../context/AuthContext";
import { GAME_MODES } from "./constants";

export const AddGameDialog = ({ setIsOpen, onAdd }) => {
  const { createGame } = useAuth();

  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [course, setCourse] = useState();
  const [score, setScore] = useState();
  const [strokes, setStrokes] = useState();
  const [coursePar, setCoursePar] = useState();
  const [scorecardId, setScorecardId] = useState();
  const [error, setError] = useState({});
  const [gameMode, setGameMode] = useState(GAME_MODES.TOTAL_SCORE);

  const closeDialog = () => {
    setIsOpen(false);
  };

  const validateDate = (value) => {
    if (!value) {
      setError({ ...error, date: "Date is required" });
      return false;
    }

    const date = new Date(value);
    if (isNaN(date.getTime())) {
      setError({ ...error, date: "Invalid date" });
      return false;
    } else {
      const { date, ...rest } = error;
      setError(rest);
      return true;
    }
  };

  const validateCourse = (value) => {
    let isValid = true;
    if (!value) {
      isValid = false;
      setError({ ...error, course: "Course name is required" });
    } else {
      if (value.length > 50) {
        isValid = false;
        setError({ ...error, course: "Course name is too long" });
      } else {
        isValid = true;
        const { course, ...rest } = error;
        setError(rest);
      }
    }

    return isValid;
  };

  const validateNumber = (value) => {
    let isValid = true;
    if (!value) {
      isValid = false;
    } else {
      const number = parseInt(value, 10);
      const checkVal =
        number &&
        number < 200 &&
        !Number.isNaN(number) &&
        !value.includes(".") &&
        !value.includes("-");

      isValid = checkVal;
    }

    return isValid;
  };

  const validateStrokes = (value) => {
    const isValid = validateNumber(value);

    if (!isValid && gameMode === GAME_MODES.SCORECARD) {
      setError({ ...error, strokes: "Invalid number of strokes" });
    } else {
      const { strokes, ...rest } = error;
      setError(rest);
    }
  };

  const validateCoursePar = (value) => {
    const isValid = validateNumber(value);

    if (!isValid) {
      setError({ ...error, par: "Invalid number of strokes for Par" });
    } else {
      const { par, ...rest } = error;
      setError(rest);
    }
  };

  const handleAddGame = () => {
    const isValid = !Object.keys(error)?.length;
    const strokesValid =
      (gameMode === GAME_MODES.TOTAL_SCORE && strokes) ||
      gameMode === GAME_MODES.SCORECARD;

    if (isValid && course && strokesValid && coursePar && date) {
      createGame({
        date,
        course,
        score,
        strokes,
        coursePar,
        scorecardId,
        gameMode,
      });

      closeDialog();
      onAdd();
    }
  };

  return (
    <Layer
      background="white"
      animation="fadeIn"
      onEsc={closeDialog}
      onClickOutside={closeDialog}
    >
      <Box
        direction="column"
        pad={{ horizontal: "large", vertical: "small" }}
        width="large"
        overflow="auto"
      >
        <Heading level={3}>Add New Game</Heading>

        <Box
          flex="grow"
          pad={{ vertical: "medium" }}
          gap="medium"
          direction="column"
        >
          <Box direction="column">
            <Text weight="bold">Date</Text>
            <div className="date-input">
              <input
                className="custom-dateinput"
                type="date"
                value={date}
                onChange={(e) => {
                  if (validateDate(e.target.value)) {
                    if (!e.target.value) {
                      setDate(new Date().toISOString().split("T")[0]);
                    } else {
                      setDate(e.target.value);
                    }
                  }
                }}
              />
            </div>
          </Box>
          <Box direction="column">
            <Text weight="bold">Course Name</Text>
            <TextInput
              onChange={(e) => setCourse(e.target.value)}
              onBlur={(e) => validateCourse(e.target.value)}
              placeholder="Augusta National"
            />
          </Box>
          <Box direction="column">
            <Text weight="bold">Course Par</Text>
            <input
              className="custom-dateinput"
              type="number"
              onChange={(e) => {
                const newVal = e.target.value?.length ? e.target.value : 0;
                setCoursePar(newVal);
                setScore(parseInt(strokes ?? 0, 10) - parseInt(newVal, 10));
              }}
              onBlur={(e) => validateCoursePar(e.target.value)}
              placeholder="72"
            />
          </Box>
          <Box direction="column" gap="small">
            <Text weight="bold">Game Type</Text>
            <RadioButtonGroup
              direction="row"
              name="game-mode"
              options={[GAME_MODES.TOTAL_SCORE, GAME_MODES.SCORECARD]}
              value={gameMode}
              onChange={(e) => {
                setGameMode(e.target.value);
                if (e.target.value === GAME_MODES.SCORECARD) {
                  const { strokes, ...rest } = error;
                  setError(rest);
                }
              }}
            />
          </Box>
          {gameMode === GAME_MODES.TOTAL_SCORE && (
            <Box gap="medium">
              <Box direction="column">
                <Text weight="bold">Number of Strokes</Text>
                <input
                  className="custom-dateinput"
                  type="number"
                  onChange={(e) => {
                    const newVal = e.target.value?.length ? e.target.value : 0;
                    setStrokes(newVal);
                    setScore(
                      parseInt(newVal, 10) - parseInt(coursePar ?? 0, 10)
                    );
                  }}
                  onBlur={(e) => validateStrokes(e.target.value)}
                  placeholder="78"
                />
              </Box>
              <Box direction="column">
                <Text weight="bold">Score</Text>
                <TextInput value={score > 0 ? `+${score}` : score ?? 0} />
              </Box>
            </Box>
          )}
          {gameMode === GAME_MODES.SCORECARD && (
            <>
              <Text size="small">
                *Scorecard will be available after the game is created
              </Text>
              <Box margin={{ top: "medium" }} direction="column">
                <Box direction="row" align="center" gap="small">
                  <Text weight="bold">Scorecard ID (Optional)</Text>
                  <Tip
                    className="scorecard-tip"
                    content={
                      <Box pad="medium" width="medium">
                        If a friend has an existing scorecard for a shared game,
                        please enter the ID found on the scorecard here. If you
                        would like to track your game separately, keep this
                        blank
                      </Box>
                    }
                  >
                    <HelpOption />
                  </Tip>
                </Box>
                <TextInput
                  onChange={(e) => setScorecardId(e.target.value)}
                  placeholder="5111234-7af7-46f7-ac31-2e89dfbcdc90"
                />
              </Box>
            </>
          )}

          {!!Object.keys(error)?.length && (
            <Box direction="row" align="center" justify="center">
              <Text weight="bold" color="status-error">
                {Object.values(error)[0]}
              </Text>
            </Box>
          )}

          <Box gap="small" direction="row" justify="end">
            <div>
              <Button
                className="small"
                onClick={closeDialog}
                primary
                color="light-4"
                label="Cancel"
              />
            </div>
            <div>
              <Button
                className="small"
                onClick={handleAddGame}
                primary
                color="neutral-1"
                label="Add Game"
                disabled={
                  Object.keys(error)?.length ||
                  !course ||
                  (!strokes && gameMode === GAME_MODES.TOTAL_SCORE) ||
                  !coursePar
                }
              />
            </div>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
